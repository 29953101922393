var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Instore-detail" },
    [
      _c("detail", {
        attrs: {
          billType: _vm.billType,
          uuid: _vm.uuid,
          tableBillSourceCout: 1,
          tableSpecialCount: 1,
        },
        scopedSlots: _vm._u([
          {
            key: "tableBillSource",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: {
                    label: "单据源",
                    width: "160",
                    align: "center",
                    formatter: _vm.id2Content,
                    "show-overflow-tooltip": "",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "tableSpecial",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: {
                    label: "应收数量",
                    prop: "importUnitQty",
                    align: "center",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }