<template>
  <!-- 采购退货单 -->
  <div class="back-detail">
    <detail :billType="billType" :uuid="uuid" :tableBillSourceCout="1" :tableSpecialCount="1">
      <template #tableBillSource>
        <el-table-column
          label="单据源"
          width="160"
          align="center"
          :formatter="id2Content"
          show-overflow-tooltip
        >
        </el-table-column>
      </template>
      <template #tableSpecial>
        <el-table-column label="可退数量" align="center" prop="importUnitQty">
        </el-table-column>
      </template>
    </detail>
  </div>
</template>

<script>
import detail from '@/views/components/bill/detail-layout.vue';
export default {
  name:'PurchaseBackDetail',
  components:{ detail },
  data () {
    return {
      billType:'110103',
      uuid:'c5fade0a-abf7-7e57-ae82-608962d69ee5'
    }
  },
  methods: {
    id2Content (row, column, cellValue, index) {
      if(row.sourceBillDetailId) {
        return '采购入库单'
      }
    }
  }
}
</script>

