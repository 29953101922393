<template>
  <!-- 采购订单 -->
  <div class="purchase-order-detail">
    <detail :billType="billType" :uuid="uuid" :searchType="1">
      <!-- 插槽 -->
    </detail>
  </div>
</template>

<script>
import detail from "@/views/components/bill/detail-layout.vue";

export default {
  name: "PurchaseOrderDetail",
  components: { detail },
  data() {
    return {
      billType: "110101",
      uuid:'97bd3046-9ef6-23f1-4f60-de52d75045b0'
    };
  },
};
</script>

<style></style>
